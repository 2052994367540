<template>
    <div>
        <vx-card title="Approval Payment Proposal">
            <vs-tabs :color="colorx" >
                <vs-tab @click="colorx = 'danger'" label="Open">
                    <div class="con-tab-ejemplo">
                        <open/>
                    </div>
                </vs-tab>
                <vs-tab @click="colorx = 'success'" label="Approved">
                    <div class="con-tab-ejemplo">
                        <approved/>
                    </div>
                </vs-tab>
                <vs-tab @click="colorx = 'warning'" label="Rejected">
                    <div class="con-tab-ejemplo">
                        <rejected/>
                    </div>
                </vs-tab>
            </vs-tabs>
        </vx-card>
    </div>
</template>
<script>
import open from "./open/open.vue";
import approved from "./approved/approved.vue";
import rejected from "./rejected/rejected.vue";

export default {
    components: {
        open,
        approved,
        rejected,
    },
    data:() => ({
        colorx: "danger",
        tabs: 0,
    })
}
</script>